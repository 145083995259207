import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

// Style imports
import './PostControls.scss';

const PostControls = ({
    previous,
    next,
}) => {
    return (
        <nav className='post-controls' aria-label='Blog post controls'>
            <div className='post-controls__container container'>
                <ul className='post-controls__list'>
                    {previous && (
                        <li className='post-controls__list-item'>
                            <Link className='post-controls__link post-controls__link--previous button' to={`/blog/${previous.frontmatter.path}`} rel='prev'>
                                <span className='post-controls__link-text'>Previous</span>
                                <span className='post-controls__assistive'>article</span>
                            </Link>
                        </li>
                    )}

                    {next && (
                        <li className={`post-controls__list-item${!previous ? ' post-controls__list-item--only' : ''}`}>
                            <Link className='post-controls__link button' to={`/blog/${next.frontmatter.path}`} rel='next'>
                                <span className='post-controls__link-text'>Next</span>
                                <span className='post-controls__assistive'>article</span>
                            </Link>
                        </li>
                    )}
                </ul>
            </div>
        </nav>
    );
};

PostControls.propTypes = {
    previous: PropTypes.object,
    next: PropTypes.object,
};

export default PostControls;