import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// Layout Imports
import Default from '../Default/Default';
import SEO from '../SEO/SEO';

// Component Imports
import Introduction from '../../components/shared/Introduction/Introduction';
import PageSelector from '../../components/shared/PageSelector/PageSelector';
import Circle from '../../components/shared/Circle/Circle';
import TagList from '../../components/shared/TagList/TagList';
import PostControls from '../../components/PostControls/PostControls';

// Style Imports
import './BlogPost.scss';
import './Typography.scss';

// Asset Imports
import Me from '../../assets/images/content/me.svg';

const BlogPost = (props) => {
  const post = props.data.mdx;
  const siteTitle = props.data.site.siteMetadata.title;
  const { previous, next } = props.pageContext;

  const handleAnimation = () => {
    gsap.registerPlugin(ScrollTrigger);

    gsap.to('#blog-post-circle-desktop', {
      scrollTrigger: {
        trigger: '#blog-post-circle-desktop',
        scrub: 1,
        markers: false,
      },
      rotation: 720,
      ease: 'none',
    });

    gsap.to('#blog-post-circle-mobile', {
      scrollTrigger: {
        trigger: '#blog-post-circle-mobile',
        scrub: 1,
        markers: false,
      },
      rotation: 720,
      ease: 'none',
    });
  };

  useEffect(() => {
    handleAnimation();
  }, []);

  return (
    <Default
      location={props.location}
      title={siteTitle}
    >
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description}
      />

      <Introduction
        heading={post.frontmatter.title}
        description={post.frontmatter.description}
        imageSource={Me}
      />

      <PageSelector
        id='mobile-nav-selector'
        triggerText='Blog post'
      />

      <div className='blog-post'>
        <div className='blog-post__container container'>

          <div className='blog-post__header'>
            <p className='blog-post__info'>Posted {post.frontmatter.date}</p>
            <p className='blog-post__info'>By {post.frontmatter.author}</p>

            <div className='blog-post__info'>
              {post.frontmatter.tags && post.frontmatter.tags.length && (
                <TagList
                  tags={post.frontmatter.tags}
                  prepend='Tags'
                />
              )}
            </div>
          </div>

          <div className='blog-post__body typography'>
            <MDXRenderer>{post.body}</MDXRenderer>
          </div>
        </div>
        
        <PostControls
          previous={previous}
          next={next}
        />
        
        {/* Desktop Circles */}
        <Circle
          color='#7E8588'
          height={575}
          width={575}
          top={-230}
          right={-170}
          opacity={0.1}
          desktop
        />

        <Circle
          color='#7E8588'
          height={535}
          width={535}
          top={-230}
          right={-170}
          opacity={0.1}
          thickness={40}
          desktop
        />

        <Circle
          color='#7E8588'
          height={360}
          width={360}
          top={175}
          right={54}
          opacity={0.05}
          thickness={40}
          desktop
        />

        <Circle
          variant='gradient'
          height={576}
          width={576}
          bottom={-215}
          left={-195}
          desktop
          id='blog-post-circle-desktop'
        />

        {/* Mobile Circles */}
        <Circle
          color='#7E8588'
          height={282}
          width={282}
          top={-180}
          right={-140}
          opacity={0.1}
          thickness={15}
          mobile
        />

        <Circle
          color='#7E8588'
          height={252}
          width={252}
          top={-180}
          right={-140}
          opacity={0.1}
          thickness={30}
          mobile
        />

        <Circle
          color='#7E8588'
          height={235}
          width={235}
          top={40}
          right={-130}
          opacity={0.05}
          thickness={20}
          mobile
        />

        <Circle
          variant='gradient'
          height={311}
          width={311}
          bottom={-90}
          left={-177}
          mobile
          id='blog-post-circle-mobile'
        />
      </div>
    </Default>
  );
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      body
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        author
        tags
      }
    }
  }
`;